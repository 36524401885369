import { makeStyles } from "@material-ui/core";
import { lighten } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "90vh",
    paddingTop: "5rem",
    paddingBottom: "2rem",
  },
  fahrzeuge: {
    marginTop: "2rem",
    marginBottom: "2rem",
  },
  paper: {
    padding: "1rem",
    marginBottom: "1rem",
  },
  chip: {
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    fontSize: "1rem",
    letterSpacing: "2px",
    textTransform: "uppercase",
  },
  content: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "90%",
  },
  grid: {
    marginRight: "0.5rem",
    marginBottom: "0.5rem",
  },
  note: {
    marginTop: "1rem",
  },
  btn: {
    color: theme.palette.primary.light,
    padding: "0.5rem",
    fontWeight: "bold",
    background: theme.palette.primary.main,
    "&:hover": {
      background: lighten(theme.palette.primary.main, 0.2),
    },
  },
  icon: {
    fontSize: "2rem",
    marginRight: "1rem",
  },
  collapse: {
    transition: "1s",
  },

  uploads: {
    marginTop: "1rem",
    display: "flex",
    justifyContent: "space-between",
  },

  input: {
    display: "none",
  },
  paperImage: {
    width: "100%",
    marginBottom: "1rem",
  },

  container: {
    display: "flex",
    alignItems: "center",
  },
  gridBtn: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "1rem",
  },
  saveBtn: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.light,
    "&:hover": {
      background: lighten(theme.palette.primary.main, 0.2),
    },
  },
  text: {
    marginTop: "2rem",
  },
  image1: {
    borderRadius: "5px",
    boxShadow: "0.5px 0.5px 5px #000",
  },
  image2: {
    borderRadius: "5px",
    marginTop: "1rem",
    boxShadow: "0.5px 0.5px 5px #000",
  },
  upload: {
    paddingRight: "1rem",
  },
  uploadBox: {
    width: "80%",
  },
  divider: {
    width: "80%",
  },
  formControl: {
    marginTop: theme.spacing(1),
    minWidth: 120,
  },

  container: {
    paddingTop: "40px",
  },
  paperSetting: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.light,
  },
  tabs: {
    [theme.breakpoints.down("sm")]: {
      flexDirecion: "vertical",
    },
  },

  paper: {
    marginTop: theme.spacing(6),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  btn: {
    textAlign: "center",
  },

  paperSteps: {
    margin: "1rem 0rem",
    padding: "1rem",
  },
  div: {
    display: "flex",
    alignItems: "center",
    marginBottom: "1rem",
  },
  icon: {
    fontSize: "2rem",
    marginRight: "1rem",
    color: theme.palette.primary.main,
  },
  typo: {
    fontWeight: "bold",
  },

  btnAddVehicle: {
    color: theme.palette.primary.light,
    padding: "0.5rem",
    fontWeight: "bold",
    background: theme.palette.primary.main,
    "&:hover": {
      background: lighten(theme.palette.primary.main, 0.2),
    },
  },
  iconAddVehicle: {
    fontSize: "2rem",
    marginRight: "1rem",
  },
}));

export default useStyles;
