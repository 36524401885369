import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import MyTextField from "../components/fields/MyTextField";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Loading from "../components/Loading";
import { Typography } from "@material-ui/core";
import Footer from "../components/nav/Footer";
import Navbar from "../components/nav/Nav";
import Message from "../components/authenticated/Message";
import { forgotPassword } from "../api";
import { SITE_KEY } from "../helper";
import { Alert, AlertTitle } from "@material-ui/lab";
import { loadRecaptcha } from "../helper";
import { useMatomo } from "@datapunt/matomo-tracker-react";

import useStyles from "../styles/main";

import { responseMessages } from "../helper/responseHelper";

const initialValues = {
  email: "",
};

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email("Bitte geben Sie eine gültige Email ein.")
    .required("Bitte geben Sie Ihre Email ein."),
});

const ForgotPassword = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(false);
  const [dirty, setDirty] = useState(false);
  const { trackPageView } = useMatomo(false);

  React.useEffect(() => {
    trackPageView();
  }, []);

  useEffect(() => {
    if (dirty) {
      loadRecaptcha();
    }
  }, [dirty]);

  const submitData = (data) => {
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(SITE_KEY, { action: "thgresetpassword" })
        .then((token) => requestToServer(data, token));
    });
  };

  const requestToServer = (data, token) => {
    setLoading(true);

    forgotPassword(data).then((result) => {
      setLoading(false);
      setResult(result);
    });
  };

  if (loading) return <Loading />;
  return (
    <div className={classes.root}>
      <Navbar />
      <Box my={10}>
        <Container component="main" maxWidth="sm">
          {result ? (
            (() => {
              if (result?.success === false) {
                const response = responseMessages(result?.error_code);
                return (
                  <Alert severity="error">
                    <AlertTitle>{response["title"]}</AlertTitle>
                    <Typography variant="body1">
                      {response["message"]}
                    </Typography>
                  </Alert>
                );
              }
              if (result?.success === true) {
                return (
                  <Alert severity="success">
                    <AlertTitle>Passwort vergessen</AlertTitle>
                    <Typography variant="body1">
                      Wir prüfen, ob ein Konto mit der angegebenen E-Mail
                      Adresse verknüpft ist und senden Ihnen gegebenenfalls
                      Anweisungen, wie Sie Ihr Passwort zurücksetzen können.
                    </Typography>
                    <Typography variant="body1">
                      Wenn Sie keine E-Mail von uns erhalten, verwenden Sie
                      bitte eine gültige E-Mail-Adresse, um das Passwort Ihres
                      Kontos zurückzusetzen.
                    </Typography>
                  </Alert>
                );
              }
            })()
          ) : (
            <Formik
              initialValues={initialValues}
              validationSchema={ForgotPasswordSchema}
              onSubmit={(values, formikProps) => {
                // same shape as initial values
                submitData(values, formikProps);
              }}
            >
              {(props) => {
                if (!dirty && props.dirty) {
                  setDirty(true);
                }
                return (
                  <Form className={classes.login}>
                    <Message />
                    <CssBaseline />
                    <div className={classes.paper}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography variant="h6">
                            Haben Sie Ihr Kontopasswort vergessen?
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body1">
                            Bitte gib deine E-Mail-Adresse ein um dein Passwort
                            zurückzusetzen.
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <MyTextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            name="email"
                            label="E-Mail Adresse"
                            id="email"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          className={classes.btn}
                          justifyContent="flex-end"
                        >
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                          >
                            Senden
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                    <Box mt={8}></Box>
                  </Form>
                );
              }}
            </Formik>
          )}
        </Container>
      </Box>
      <Footer />
    </div>
  );
};

export default ForgotPassword;
